<template>
  <div>
    <VAppBar color="grey lighten-5" height="56" class="header__wrapper">
      <img :src="require('@/assets/images/logo_dark_ua.svg')" height="33px" />
      <VSpacer></VSpacer>

      <div :style="!$vuetify.breakpoint.mobile ? 'max-width:100px' : 'max-width:80px'">
        <a href="https://savelife.in.ua/" target="_blank">
          <v-img :src="require('@/assets/images/logo_come_back_alive_ukr.svg')" />
        </a>
      </div>

      <theme :isOutlined="false" />

      <language :isOutlined="false" />
    </VAppBar>
  </div>
</template>

<script>
import Language from "./Language.vue";
import Theme from "@/components/layout/theme.vue";

export default {
  components: {
    Theme,
    Language
  }
};
</script>

<style></style>
